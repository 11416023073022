import * as dayjs from "dayjs";
import * as weekOfYear from "dayjs/plugin/weekOfYear";
import * as weekday from "dayjs/plugin/weekday";

dayjs.extend(weekOfYear);
dayjs.extend(weekday);

const DAYS = { sun: 7, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };

const RED = 0;
const YELLOW = 1;

const readQueryParams = () => {
  const searchParams = new URL(window.location.href).searchParams;
  const day = DAYS?.[searchParams.get("day")] ?? 1;
  const red = searchParams.get("red") ?? "even";
  return { day, red };
};

const setCssClass = (name: string, el = "body") =>
  (document.querySelector(el).className = name);

const isEven = (value: number) => value % 2 === 0;

const isOdd = (value: number) => !isEven(value);

const updateColour = (date = dayjs()) => {
  const { day, red } = readQueryParams();
  const binDay = dayjs().weekday(day).endOf("day");
  const weekNumber = dayjs().week();
  const isRedWeek = red === "even" ? isEven(weekNumber) : isOdd(weekNumber);

  let index;
  if (isRedWeek) {
    index = date > binDay ? YELLOW : RED;
  } else {
    index = date > binDay ? RED : YELLOW;
  }
  setCssClass(`week${index}`);
};

const run = () => {
  console.info('Requires "day" and "red" query parameters.');
  console.info(
    '"Day" is one of "mon", "tue", "wed", "thu", "fri", "sat", "sun"',
  );
  console.info('"red" is either "even" or "odd"');
  console.info(
    'Set the "day" of the week that the bin goes our and whether the "red" bin goes out on an even or odd week of the year',
  );
  updateColour();
  setInterval(updateColour, 60 * 1000);
};

run();
